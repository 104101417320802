import {
	Grid,
	IconButton,
	InputAdornment,
	InputBase,
	Paper,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Popper from "@material-ui/core/Popper";
import Typography from "@material-ui/core/Typography";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import { AuthorizationContext } from "components/App";
import {
	CancelAppointment,
	searchAppointments,
	sendReminder,
} from "../../../apis";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import debounce from "utils/debounce";
import {
	DropdownButton,
	OpenTelehealtButton,
	ResendButton,
} from "../../Calendar/Buttons";
import ClipboardButton from "../../Calendar/ClipboardButton";
import ErrorNotification from "../../common/ErrorNotification";
import useStyles from "./style";
import { useDebounce } from "./useDebounce";

const createDateObjectFromTimeString = (timeString) =>
	moment(timeString, "hh:mm a").toDate();

const changeDateOnDateObject = (targetObject, sourceObject) => {
	const date = moment(sourceObject).date();
	const month = moment(sourceObject).month();
	const year = moment(sourceObject).year();
	return moment(targetObject).set({ date, month, year }).toDate();
};

const ResultItem = ({
	appointment,
	onClickEdit,
	notificationMessage,
	notificationOpen,
	notificationStatus,
	view,
	updateAppointments,
}) => {
	const {
		AppointmentID,
		FirstName,
		LastName,
		Phone,
		SubmissionID,
		PatientID,
		AppointmentStartTime,
		AppointmentEndTime,
		ClinicName,
		SelectProvider,
		PatientEMRNumber,
		AppointmentType,
		TeleHealthURL,
		InPersonOrTelehealth,
		AppIdHash,
		Cancel,
		ZipCode,
	} = appointment;
	const classes = useStyles({ Cancel });
	const searchGridCell = useRef(null);

	const timeLabel = moment(AppointmentStartTime).format("MMMM DD, YYYY");
	const time = `${moment(AppointmentStartTime).format("h:mma")} - ${moment(
		AppointmentEndTime,
		"hh:mm a"
	).format("h:mma")}`;

	const trueEndTime = changeDateOnDateObject(
		createDateObjectFromTimeString(AppointmentEndTime),
		AppointmentStartTime
	);

	const rangeDateAppointment =
		moment().diff(moment(AppointmentStartTime).subtract(15, "minutes")) < 0 ||
		moment().diff(moment(trueEndTime).add(15, "minutes")) > 0;

	useEffect(() => {
		// if (!Cancel || !searchGridCell.current) {
		//   return
		// } else {
		//   const currentSearchCell = searchGridCell.current
		//   currentSearchCell.classList.add("cancel")
		// }
	}, [searchGridCell, Cancel]);

	return (
		<>
			{view === "dayGridMonth" ? (
				<ListItem divider ref={searchGridCell} className={classes.listItem}>
					<ListItemText
						secondary={
							<Grid
								container
								wrap="nowrap"
								direction="column"
								className={classes.ResultAppointmentContainer}>
								<Grid container wrap="nowrap">
									<Grid container item direction="column" wrap="nowrap" sx={3}>
										<Typography className={classes.fontWeightBold}>
											{FirstName}&nbsp;{LastName}
										</Typography>
										<Typography>{time}</Typography>
										<Typography>{timeLabel}</Typography>
										<Typography className={classes.typography}>
											{Phone}
										</Typography>

										<Typography className={classes.fontWeightBold}>
											EMR ID:
											<span style={{ fontWeight: "normal", display: "inline" }}>
												{PatientEMRNumber}
											</span>
										</Typography>
										<Typography className={classes.fontWeightBold}>
											Confirmation number:{" "}
											<span style={{ fontWeight: "normal", display: "inline" }}>
												{PatientID}
											</span>
										</Typography>
										<Typography className={classes.fontWeightBold}>
											Zip Code:{" "}
											<span style={{ fontWeight: "normal", display: "inline" }}>
												{ZipCode}
											</span>
										</Typography>
									</Grid>
									<Grid item container direction="column" wrap="nowrap" sx={3}>
										<Grid wrap="wrap">
											<Typography
												component={"span"}
												className={classes.fontWeightBold}>
												Clinic:&nbsp;
											</Typography>
											<Typography
												className={classes.typography}
												component={"span"}>
												{ClinicName}
											</Typography>
										</Grid>
										<Grid wrap="nowrap">
											<Typography
												component={"span"}
												className={classes.fontWeightBold}>
												Provider:&nbsp;
											</Typography>
											<Typography
												className={classes.typography}
												component={"span"}>
												{SelectProvider}
											</Typography>
										</Grid>
										<Grid wrap="nowrap">
											<Typography
												component={"span"}
												className={classes.fontWeightBold}>
												Appointment&nbsp;Type:&nbsp;
											</Typography>
											<Typography
												className={classes.typography}
												component={"span"}>
												{AppointmentType}
											</Typography>
										</Grid>
										<Grid wrap="nowrap">
											<Typography
												component={"span"}
												className={classes.fontWeightBold}>
												Telehealth&nbsp;Or&nbsp;In-Person:&nbsp;
											</Typography>
											<Typography
												className={classes.typography}
												component={"span"}>
												{InPersonOrTelehealth}
											</Typography>
										</Grid>
									</Grid>
									<Grid
										item
										xs={6}
										container
										margin={2}
										padding={4}
										direction="column"
										wrap="nowrap">
										{!Cancel && (
											<>
												<Grid item sx={12}>
													<DropdownButton
														inPersonOrTelehealth={InPersonOrTelehealth}
														classStyle={classes.buttonStyle}
														onClickEdit={onClickEdit}
														setNotificationMessage={notificationMessage}
														setNotificationStatus={notificationStatus}
														setNotificationOpen={notificationOpen}
														cancelAppointment={CancelAppointment}
														appointment={appointment}
														trueEndTime={trueEndTime}
														updateAppointments={updateAppointments}
													/>
												</Grid>
												{InPersonOrTelehealth === "Telehealth" ? (
													<Grid item sx={12}>
														<ResendButton
															disabledButton={moment().diff(trueEndTime) > 0}
															classStyle={classes.buttonStyle}
															setNotificationOpen={notificationOpen}
															setNotificationMessage={notificationMessage}
															setNotificationStatus={notificationStatus}
															sendReminder={sendReminder}
															appointmentID={AppointmentID}
														/>
													</Grid>
												) : null}
												{InPersonOrTelehealth === "Telehealth" ? (
													<Grid item sx={12}>
														<OpenTelehealtButton
															disabledButton={
																TeleHealthURL === "" ||
																moment().diff(
																	moment(AppointmentStartTime).subtract(
																		15,
																		"minutes"
																	)
																) < 0 ||
																moment().diff(
																	moment(trueEndTime).add(15, "minutes")
																) > 0
															}
															classStyle={classes.buttonStyle}
															url={
																TeleHealthURL !== null
																	? TeleHealthURL.replace("patient", "provider")
																	: ""
															}
														/>
													</Grid>
												) : null}
												{/* <Grid sx={12} container direction='row' wrap='nowrap' className={classes.pin}> */}
												<Grid item sx={12}>
													{InPersonOrTelehealth === "Telehealth" &&
													AppIdHash !== null ? (
														<ClipboardButton
															value={AppIdHash}
															validation={
																TeleHealthURL === "" || rangeDateAppointment
															}
														/>
													) : null}
												</Grid>
											</>
										)}
									</Grid>
								</Grid>
							</Grid>
						}
					/>
				</ListItem>
			) : (
				<ListItem divider ref={searchGridCell}>
					<ListItemText
						secondary={
							<Grid container wrap="nowrap" direction="column">
								<Grid container item wrap="nowrap" direction="row">
									<Grid container wrap="nowrap" direction="column">
										<Typography className={classes.fontWeightBold}>
											{FirstName}&nbsp;{LastName}
										</Typography>
										<Typography>{time}</Typography>
										<Typography>{timeLabel}</Typography>
										<Typography className={classes.typography}>
											{Phone}
										</Typography>

										<Typography className={classes.fontWeightBold}>
											EMR ID:
											<span style={{ fontWeight: "normal", display: "inline" }}>
												{PatientEMRNumber}
											</span>
										</Typography>
										<Typography className={classes.fontWeightBold}>
											Confirmation number:{" "}
											<span style={{ fontWeight: "normal", display: "inline" }}>
												{PatientID}
											</span>
										</Typography>
										<Typography className={classes.fontWeightBold}>
											Zip Code:{" "}
											<span style={{ fontWeight: "normal", display: "inline" }}>
												{ZipCode}
											</span>
										</Typography>
									</Grid>
									<Grid container wrap="wrap" direction="column" spacing={1}>
										<Grid wrap="wrap">
											<Typography
												component={"span"}
												className={classes.fontWeightBold}>
												Clinic:&nbsp;
											</Typography>
											<Typography
												component={"span"}
												className={classes.typography}>
												{ClinicName}
											</Typography>
										</Grid>
										<Grid wrap="wrap">
											<Typography
												component={"span"}
												className={classes.fontWeightBold}>
												Provider:&nbsp;
											</Typography>
											<Typography
												component={"span"}
												className={classes.typography}>
												{SelectProvider}
											</Typography>
										</Grid>
										<Grid wrap="wrap">
											<Typography
												component={"span"}
												className={classes.fontWeightBold}>
												Appointment&nbsp;Type:&nbsp;
											</Typography>
											<Typography
												component={"span"}
												className={classes.typography}>
												{AppointmentType}
											</Typography>
										</Grid>
										<Grid wrap="wrap">
											<Typography
												component={"span"}
												className={classes.fontWeightBold}>
												Telehealth&nbsp;Or&nbsp;In-Person:&nbsp;
											</Typography>
											<Typography
												component={"span"}
												className={classes.typography}>
												{InPersonOrTelehealth}
											</Typography>
										</Grid>
									</Grid>
								</Grid>
								<Grid
									container
									wrap="nowrap"
									direction="column"
									sx={{ marginTop: 1 }}>
									{/* <Grid item sx={6}> */}
									{!Cancel && (
										<>
											<Grid item>
												<DropdownButton
													inPersonOrTelehealth={InPersonOrTelehealth}
													classStyle={classes.buttonStyle}
													onClickEdit={onClickEdit}
													setNotificationMessage={notificationMessage}
													setNotificationStatus={notificationStatus}
													setNotificationOpen={notificationOpen}
													cancelAppointment={CancelAppointment}
													appointment={appointment}
													trueEndTime={trueEndTime}
													updateAppointments={updateAppointments}
												/>
											</Grid>
											{InPersonOrTelehealth === "Telehealth" ? (
												<Grid item>
													<ResendButton
														disabledButton={moment().diff(trueEndTime) > 0}
														classStyle={classes.buttonStyle}
														setNotificationOpen={notificationOpen}
														setNotificationMessage={notificationMessage}
														setNotificationStatus={notificationStatus}
														sendReminder={sendReminder}
														appointmentID={AppointmentID}
													/>
												</Grid>
											) : null}
											{/* </Grid>
                  <Grid item sx={6}> */}
											{InPersonOrTelehealth === "Telehealth" ? (
												<Grid item>
													<OpenTelehealtButton
														disabledButton={
															TeleHealthURL === "" ||
															moment().diff(
																moment(AppointmentStartTime).subtract(
																	15,
																	"minutes"
																)
															) < 0 ||
															moment().diff(
																moment(trueEndTime).add(15, "minutes")
															) > 0
														}
														classStyle={classes.buttonStyle}
														url={
															TeleHealthURL !== null
																? TeleHealthURL.replace("patient", "provider")
																: ""
														}
													/>
												</Grid>
											) : null}
											{InPersonOrTelehealth === "Telehealth" &&
											AppIdHash !== null ? (
												<Grid item>
													<ClipboardButton
														value={AppIdHash}
														validation={
															TeleHealthURL === "" || rangeDateAppointment
														}
													/>
												</Grid>
											) : null}
										</>
									)}
								</Grid>
							</Grid>
						}
					/>
				</ListItem>
			)}
		</>
	);
};

const ResultBox = ({ results, isLoading, ...propsResultItem }) => {
	const classes = useStyles();
	const resultsIsEmpty = results.length === 0;

	if (isLoading) {
		return (
			<Paper elevation={6}>
				<Grid
					className={classes.progressRoot}
					alignItems="center"
					justify="center"
					container>
					<CircularProgress color="primary" />
				</Grid>
			</Paper>
		);
	}

	if (resultsIsEmpty) {
		return (
			<Paper elevation={6}>
				<List className={classes.listRoot}>
					<ListItem>
						<ListItemText secondary="No results found." />
					</ListItem>
				</List>
			</Paper>
		);
	}
	return (
		<Paper elevation={6}>
			<List className={classes.listRoot}>
				{results.map((item) => (
					<ResultItem appointment={item} {...propsResultItem} />
				))}
			</List>
		</Paper>
	);
};

function SearchBar({ onClickEdit, view, updateAppointments }) {
	const classes = useStyles();
	const [inputValue, setInputValue] = useState("");
	const debouncedValue = useDebounce(inputValue, 500);
	const [anchorEl, setAnchorEl] = useState(null);
	const [isLoading, setIsLoading] = useState();

	const [notificationOpen, setNotificationOpen] = React.useState(false);
	const [notificationMessage, setNotificationMessage] = React.useState("");
	const [notificationStatus, setNotificationStatus] = React.useState("");

	const [results, setResults] = useState();
	const { authToken } = useContext(AuthorizationContext);

	const handleChange = (e) => {
		setIsLoading(true);
		setResults([]);
		setInputValue(e.target.value);
		if (!anchorEl) setAnchorEl(e.target.parentElement);
	};

	const handleSearch = async () => {
		setIsLoading(true);
		setResults([]);
		try {
			const { message } = await searchAppointments(inputValue, authToken);
			setResults(message);
		} catch (e) {
			console.error(e.message);
		}
		setIsLoading(false);
	};

	const open = Boolean(anchorEl);
	const id = open ? "appointments-search-results" : undefined;

	useEffect(() => {
		if (!inputValue.length) {
			setAnchorEl(null);
			setResults([]);
		}
	}, [inputValue]);

	useEffect(() => {
		if (!debouncedValue) return;
		handleSearch();
	}, [debouncedValue]);

	return (
		<Grid className={classes.root} container wrap="nowrap">
			<InputBase
				fullWidth
				value={inputValue}
				onChange={handleChange}
				aria-describedby={id}
				placeholder="Search appointments..."
				inputProps={{
					className: classes.inputRoot,
					// onKeyDown: handleKeyDown,
				}}
				startAdornment={
					<InputAdornment className={classes.searchIcon}>
						{inputValue ? (
							<IconButton
								onClick={() => {
									setInputValue("");
								}}
								tabIndex={-1}
								size="small">
								<ClearIcon fontSize="medium" htmlColor="#c4c4c4" />
							</IconButton>
						) : (
							<IconButton
								disabled={inputValue.length === 0}
								// onClick={handleSearch}
								tabIndex={-1}
								size="small">
								<SearchIcon fontSize="medium" htmlColor="#c4c4c4" />
							</IconButton>
						)}
					</InputAdornment>
				}
			/>
			<Popper
				id={id}
				open={open}
				anchorEl={anchorEl}
				placement="bottom-start"
				className={classes.resultBoxRoot}
				disablePortal>
				<ResultBox
					results={results}
					onClickEdit={(id) => {
						setInputValue("");
						onClickEdit(id);
					}}
					updateAppointments={updateAppointments}
					isLoading={isLoading}
					notificationMessage={setNotificationMessage}
					notificationOpen={setNotificationOpen}
					notificationStatus={setNotificationStatus}
					view={view}
				/>
			</Popper>
			<ErrorNotification
				open={notificationOpen}
				title={notificationMessage}
				onClose={() => setNotificationOpen(false)}
				severity={notificationStatus}
			/>
		</Grid>
	);
}

SearchBar.propTypes = {};

export default SearchBar;
