import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import { makeStyles } from "@material-ui/core/styles";

import { sendReminder, CancelAppointment } from "../../../apis";

import moment from "moment";

import ClipboardButton from "../ClipboardButton";
import ErrorNotification from "../../common/ErrorNotification";
import {
	EditButton,
	ResendButton,
	OpenTelehealtButton,
	DropdownButton,
} from "../Buttons";
import { useEffect } from "react";
import { useRef } from "react";

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		borderLeftWidth: 4,
		borderLeftStyle: "solid",
		borderLeftColor: ({ Cancel }) =>
			Cancel ? "#979797" : theme.palette.primary.main,
		paddingLeft: theme.spacing(1),
		"& .MuiTypography-body1": {
			color: ({ Cancel }) => (Cancel ? "#606060" : "inherit"),
		},
	},
	button: {},
	leftContainer: {
		paddingRight: theme.spacing(2),
	},
	typography: {
		overflow: "hidden",
		whiteSpace: "nowrap",
		textOverflow: "ellipsis",
	},
	fontWeightBold: {
		fontWeight: "bold",
	},
	buttonStyle: {
		width: "100%",
		padding: "0.5rem",
		color: "#ffffff",
	},
	pin: {
		justifyContent: "space-around",
		alignItems: "center",
		width: "100%",
	},
}));

const createDateObjectFromTimeString = (timeString) =>
	moment(timeString, "h:m a").toDate();

const changeDateOnDateObject = (targetObject, sourceObject) => {
	const date = moment(sourceObject).date();
	const month = moment(sourceObject).month();
	const year = moment(sourceObject).year();
	return moment(targetObject).set({ date, month, year }).toDate();
};

function WeekGridCell({ updateAppointments, appointment, onClickEdit }) {
	const {
		AppointmentID,
		FirstName,
		LastName,
		Phone,
		Email,
		ClinicName,
		SelectProvider,
		PatientEMRNumber,
		AppointmentType,
		TeleHealthURL,
		InPersonOrTelehealth,
		AppIdHash,
		AppointmentStartTime,
		AppointmentEndTime,
		Cancel,
		PatientID,
		ZipCode,
		InterpretationLanguage,
	} = appointment;

	const classes = useStyles({ Cancel });
	const [notificationOpen, setNotificationOpen] = React.useState(false);
	const [notificationMessage, setNotificationMessage] = React.useState("");
	const [notificationStatus, setNotificationStatus] = React.useState("");
	const weekGridCellRef = useRef(null);

	const trueEndTime = changeDateOnDateObject(
		createDateObjectFromTimeString(AppointmentEndTime),
		AppointmentStartTime
	);

	const rangeDateAppointment =
		moment().diff(moment(AppointmentStartTime).subtract(15, "minutes")) < 0 ||
		moment().diff(moment(trueEndTime).add(15, "minutes")) > 0;

	useEffect(() => {
		if (!Cancel || !weekGridCellRef.current) return;

		const currentWeekCell = weekGridCellRef.current;
		const parentContainerCell = currentWeekCell.parentElement.parentElement;
		parentContainerCell.classList.add("cancel");
	}, [weekGridCellRef, Cancel]);
	console.log(InterpretationLanguage,"InterpretationLanguage")
	return (
		<Grid
			className={classes.root}
			container
			wrap="nowrap"
			ref={weekGridCellRef}>
			<Grid
				className={classes.leftContainer}
				style={{}}
				item
				xs={3}
				container
				direction="column"
				wrap="nowrap">
				<Typography
					className={`${classes.typography} ${classes.fontWeightBold}`}>{`${FirstName} ${LastName}`}</Typography>
				<Typography className={classes.typography}>{Phone}</Typography>
				<Typography className={classes.typography}>{Email}</Typography>

				<Grid wrap="wrap">
					<Typography component={"span"} className={classes.fontWeightBold}>
						EMR&nbsp;ID:&nbsp;
					</Typography>
					<Typography component={"span"}>{PatientEMRNumber}</Typography>
				</Grid>

				<Grid wrap="wrap" inline>
					<Typography component={"span"} className={classes.fontWeightBold}>
						Confirmation&nbsp;number:&nbsp;
					</Typography>
					<Typography component={"span"}>{PatientID}</Typography>
					<Grid wrap="wrap">
						<Typography component={"span"} className={classes.fontWeightBold}>
							ZipCode:&nbsp;
						</Typography>
						<Typography component={"span"}>{ZipCode}</Typography>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={5} container direction="column" wrap="nowrap">
				<Grid wrap="wrap">
					<Typography component={"span"} className={classes.fontWeightBold}>
						Clinic:&nbsp;
					</Typography>
					<Typography component={"span"}>{ClinicName}</Typography>
				</Grid>
				<Grid wrap="wrap">
					<Typography component={"span"} className={classes.fontWeightBold}>
						Provider:&nbsp;
					</Typography>
					<Typography component={"span"}>{SelectProvider}</Typography>
				</Grid>
				<Grid wrap="wrap">
					<Typography component={"span"} className={classes.fontWeightBold}>
						Appointment&nbsp;Type:&nbsp;
					</Typography>
					<Typography component={"span"}>{AppointmentType}</Typography>
				</Grid>
				<Grid wrap="wrap">
					<Typography component={"span"} className={classes.fontWeightBold}>
						Telehealth&nbsp;or&nbsp;In-person?&nbsp;
					</Typography>
					{/* {ClinicName.includes("MAB follow up" || "PrEP Phone Call") ? <Typography component={"span"}>{'Phone Call'}</Typography>:<Typography component={"span"}>{InPersonOrTelehealth}</Typography>} */}
					{ClinicName.includes( "PrEP Phone Call" ) ? <Typography component={"span"}>{'Phone Call'}</Typography> :  
					ClinicName.includes( "MAB follow up" ) ? <Typography component={"span"}>{'Phone Call'}</Typography> :  <Typography component={"span"}>{InPersonOrTelehealth}</Typography>
					}
				</Grid>
				<Grid wrap="wrap">
					<Typography component={"span"} className={classes.fontWeightBold}>
					Language:&nbsp;
					</Typography>
					{ClinicName.includes( "PrEP Phone Call") ? <Typography component={"span"}>{InterpretationLanguage}</Typography> :  
					 <Typography component={"span"}>{""}</Typography>
					}
				</Grid>
			</Grid>
			{!Cancel && (
				<Grid
					item
					xs={3}
					spacing={1}
					container
					direction="column"
					wrap="nowrap"
					justify="center"
					align="center">
					<Grid item sx={12}>
						<DropdownButton
							inPersonOrTelehealth={InPersonOrTelehealth}
							classStyle={classes.buttonStyle}
							onClickEdit={onClickEdit}
							setNotificationOpen={setNotificationOpen}
							setNotificationMessage={setNotificationMessage}
							setNotificationStatus={setNotificationStatus}
							cancelAppointment={CancelAppointment}
							appointment={appointment}
							updateAppointments={updateAppointments}
							trueEndTime={trueEndTime}
						/>
						{/* <EditButton
            classStyle={classes.buttonStyle}
            onClickEdit={onClickEdit}
            SubmissionID={SubmissionID}
          /> */}
					</Grid>
					{InPersonOrTelehealth === "Telehealth" ? (
						<Grid item sx={12}>
							<ResendButton
								disabledButton={moment().diff(trueEndTime) > 0}
								classStyle={classes.buttonStyle}
								setNotificationOpen={setNotificationOpen}
								setNotificationMessage={setNotificationMessage}
								setNotificationStatus={setNotificationStatus}
								sendReminder={sendReminder}
								appointmentID={AppointmentID}
							/>
						</Grid>
					) : null}
					{InPersonOrTelehealth === "Telehealth" ? (
						<Grid item sx={12}>
							<OpenTelehealtButton
								disabledButton={!TeleHealthURL || rangeDateAppointment}
								classStyle={classes.buttonStyle}
								url={
									!TeleHealthURL
										? "#"
										: TeleHealthURL.replace("patient", "provider")
								}
							/>
						</Grid>
					) : null}
					{/* <Grid sx={12} container direction='row' wrap='nowrap' className={classes.pin}> */}
					<Grid item sx={12}>
						{InPersonOrTelehealth === "Telehealth" && AppIdHash !== null ? (
							<ClipboardButton
								value={AppIdHash}
								validation={!TeleHealthURL || rangeDateAppointment}
							/>
						) : null}
					</Grid>
					{/* </Grid> */}
				</Grid>
			)}
			<ErrorNotification
				open={notificationOpen}
				title={notificationMessage}
				onClose={() => setNotificationOpen(false)}
				severity={notificationStatus}
			/>
		</Grid>
	);
}

WeekGridCell.propTypes = {};

export default WeekGridCell;
